import gql from "graphql-tag";

export const MANAGER_APPROVER_IMPREST = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingApprovalImprestRequests {
        id
        note
        approved
        nextApprover
        imprest {
          requested {
            amount
            code
          }
          period
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          requestStatus
          timestamp
          expenseManagement {
            id
            name
            project {
              name
              color
            }
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
          }
          travel {
            id
            beginDate
            endDate
            startWorkDate
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
            address
            country
            city
            region
            requestStatus
          }
        }
      }
    }
  }
`;
